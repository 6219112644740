<template>
  <div ref="el" class="tailored-space--desktop">
    <div class="content">
      <div class="row row-left">
        <div class="column">
          <h2 class="title">{{ props.content.title }}</h2>
          <Link :link="props.content.link" :label="props.content.link_label" />
        </div>
        <p class="paragraph">{{ props.content.paragraph }}</p>
      </div>
      <div class="row row-right">
        <p class="paragraph-small">{{ props.content.paragraph_small }}</p>

        <div class="image-container inspiration1">
          <div ref="image1" class="image inspiration">
            <CustomImage
              :image="props.content.inspirations[0].image"
              :sizes="[331, 331]"
            />
          </div>
          <h3 class="image-title">
            {{ props.content.inspirations[0].title }}
          </h3>
          <p class="image-text">{{ props.content.inspirations[0].subtitle }}</p>
        </div>
      </div>
    </div>
    <div class="inspirations-row">
      <div class="image-container inspiration2">
        <div ref="image2" class="image inspiration">
          <CustomImage
            :image="props.content.inspirations[1].image"
            :sizes="[331, 331]"
          />
        </div>
        <h3 class="image-title">
          {{ props.content.inspirations[1].title }}
        </h3>
        <p class="image-text">{{ props.content.inspirations[1].subtitle }}</p>
      </div>

      <div class="image-container inspiration3">
        <div ref="image3" class="image inspiration">
          <CustomImage
            :image="props.content.inspirations[2].image"
            :sizes="[331, 331]"
          />
        </div>
        <h3 class="image-title">
          {{ props.content.inspirations[2].title }}
        </h3>
        <p class="image-text">{{ props.content.inspirations[2].subtitle }}</p>
      </div>

      <div class="image-container inspiration4">
        <div ref="image4" class="image inspiration">
          <CustomImage
            :image="props.content.inspirations[3].image"
            :sizes="[331, 331]"
          />
        </div>
        <h3 class="image-title">
          {{ props.content.inspirations[3].title }}
        </h3>
        <p class="image-text">{{ props.content.inspirations[3].subtitle }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { gsap } from "gsap";
import Link from "@/components/Link.vue";
import ImageWithDetails from "@/components/homepage/ImageWithDetails";
const props = defineProps({
  content: Object,
});

const el = shallowRef(null);
const image1 = shallowRef(null);
const image2 = shallowRef(null);
const image3 = shallowRef(null);
const image4 = shallowRef(null);

let ctx;

onMounted(() => {
  nextTick(() => {
    initAnimations();
  });
});

const initAnimations = () => {
  ctx = gsap.context(() => {
    const container2 = document.querySelector(".inspiration2");
    const container3 = document.querySelector(".inspiration3");
    const container4 = document.querySelector(".inspiration4");

    const allImages = el.value.querySelectorAll(".img-el img");

    allImages.forEach((element) => {
      gsap.to(element, {
        y: () => element.offsetHeight * 0.2,
        ease: "none",
        scrollTrigger: {
          trigger: element,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      });
    });

    gsap.to(container2, {
      y: () => -container2.offsetHeight * 0.8,
      ease: "none",
      scrollTrigger: {
        trigger: container2,
        start: "top bottom",
        bottom: "bottom bottom",
        scrub: 0,
      },
    });

    gsap.to(container3, {
      y: () => -container3.offsetHeight * 0.5,
      ease: "none",
      scrollTrigger: {
        trigger: container3,
        start: "top bottom",
        bottom: "bottom bottom",
        scrub: 0,
      },
    });

    gsap.to(container4, {
      y: () => -container4.offsetHeight * 0.4,
      ease: "none",
      scrollTrigger: {
        trigger: container4,
        start: "top bottom",
        bottom: "bottom bottom",
        scrub: 0,
      },
    });
  });
};

onBeforeUnmount(() => {
  ctx?.kill(false);
});
</script>

<style lang="scss" scoped>
.tailored-space--desktop {
  height: calc(100% + 34.5rem);
  padding: $padding-desktop-big $padding-desktop 0;
  background-color: $white;
  overflow: hidden;
}

.content {
  @include grid-desktop();
}

.row-left {
  position: relative;
  height: max-content;
  grid-column: 1 / span 6;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 2.4rem;

  &::after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: $black;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.column {
  display: flex;
  flex-direction: column;

  grid-column: 1 / span 2;
}

.title {
  @include title-medium;
  text-transform: uppercase;
  width: 17.7rem;
  margin-bottom: 2rem;
}

.paragraph {
  @include caption-medium;

  grid-column: 4 / span 2;

  width: calc(100% + 2.4rem);
}

.row-right {
  position: relative;
  grid-column: 7 / span 6;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 2.4rem;
}

.paragraph-small {
  @include body-regular;
  grid-column: 1 / span 1;
  width: calc(100% + 2.4rem);
}

.inspirations-row {
  margin-top: 34.5rem;

  @include grid-desktop($margin: 0);
  margin-left: auto;
}

.inspiration {
  position: relative;

  overflow: hidden;
  aspect-ratio: 330 / 475;

  margin-bottom: 0.7rem;

  :deep(img) {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}

.inspiration1 {
  grid-column: 4 / span 3;
}

.inspiration2,
.inspiration3,
.inspiration4 {
  grid-column: span 3;
}

.inspiration2 {
  grid-column: 4 / span 3;
}

.inspiration2 {
  transform: translateY(-34.5rem);
}

.inspiration4 {
  margin-top: 34.5rem;
}

.image-title {
  @include caption-small;
  margin-bottom: 0.3rem;
}

.image-text {
  @include body-small;
}
</style>
