<template>
  <section
    class="tailored-space"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
  >
    <TailoredSpaceMobile v-show="isMobile" :content="slice.primary" />
    <TailoredSpaceDesktop v-show="!isMobile" :content="slice.primary" />
  </section>
</template>

<script setup lang="ts">
import { type Content } from "@prismicio/client";

import TailoredSpaceMobile from "@/components/homepage/TailoredSpaceMobile.vue";
import TailoredSpaceDesktop from "@/components/homepage/TailoredSpaceDesktop.vue";

import { useMobile } from "@/composables/useMobile";

const { isMobile } = useMobile();

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
defineProps(
  getSliceComponentProps<Content.TailoredSpaceSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ])
);
</script>

<style
  src="@/assets/css/slices/homepage/tailored-space.scss"
  lang="scss"
  scoped
></style>
