<template>
  <div class="tailored-space--mobile">
    <div class="content">
      <h2 class="title">{{ props.content.title }}</h2>
      <p class="paragraph">{{ props.content.paragraph }}</p>
      <p class="paragraph-small">{{ props.content.paragraph_small }}</p>
      <Link :link="props.content.link" :label="props.content.link_label" />
    </div>

    <Swiper
      :speed="500"
      class="swiper"
      :slides-per-view="1.3"
      :space-between="13"
    >
      <template v-for="slide in props.content.inspirations">
        <SwiperSlide class="swiper-slide">
          <ImageWithDetails
            :image="slide.image"
            :detail1="slide.title"
            :detail2="slide.subtitle"
          />
        </SwiperSlide>
      </template>
    </Swiper>
  </div>
</template>

<script setup>
import Link from "@/components/Link.vue";
import ImageWithDetails from "@/components/homepage/ImageWithDetails.vue";

const props = defineProps({
  content: Object,
});
</script>

<style
  src="@/assets/css/components/homepage/tailored-space-mobile.scss"
  lang="scss"
  scoped
></style>
